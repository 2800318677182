exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-casos-clinicos-jsx": () => import("./../../../src/pages/casos-clinicos.jsx" /* webpackChunkName: "component---src-pages-casos-clinicos-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-diagnostico-en-imagenes-jsx": () => import("./../../../src/pages/diagnostico-en-imagenes.jsx" /* webpackChunkName: "component---src-pages-diagnostico-en-imagenes-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-laboratorio-dental-digital-jsx": () => import("./../../../src/pages/laboratorio-dental-digital.jsx" /* webpackChunkName: "component---src-pages-laboratorio-dental-digital-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */),
  "component---src-pages-red-jsx": () => import("./../../../src/pages/red.jsx" /* webpackChunkName: "component---src-pages-red-jsx" */),
  "component---src-pages-registro-jsx": () => import("./../../../src/pages/registro.jsx" /* webpackChunkName: "component---src-pages-registro-jsx" */),
  "component---src-templates-caso-clinico-jsx": () => import("./../../../src/templates/caso-clinico.jsx" /* webpackChunkName: "component---src-templates-caso-clinico-jsx" */)
}

